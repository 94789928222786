@media only screen and (max-width: 1190px) {
    
 
        .campaign_btn {
            grid-template-columns: 2fr 1fr 2fr 3fr fr;
        }
    
}

@media only screen and (min-width: 600px) and (max-width:1199px) {
    .vertical_table{
        button{
            // width: 11.11%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
