.setting-div {
    .borders {
        border: 1px solid #e4e4e7;
    }

    .setting_inner {
        border: 1px solid #e4e4e7;
        border-radius: 3px;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 0.5rem 0.7rem;
        .input {
            width: 60%;
            border: 1px solid #d3d3d3;
            border-radius: 4px;
            color: #333;
            outline: none;
            padding: 7px;
            margin-left: 5px;
        }

        .buttons {
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            padding: 7px 20px;
            gap: 10px;
            color: white;
            background-color: #3b84df;
        }

        .bVhUbT {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
        }

        .coDjSU {
            justify-content: center;
        }

        .xizbp {
            justify-content: center;
        }

        .hFpEJh {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
        }
    }

    .kAgPKn {
        font-size: 16px;
        font-weight: 600;
    }
}

//acos filter  //
.acos_filter {
    table {
        border-collapse: collapse;
        width: 75%;
        margin: auto;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: center;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #f5f7fb;
    }

    input[type="number"] {
        padding: 6px;
        border: 1px solid lightgray;
        width: 63%;
        border-radius: 4px;
    }

    .acos_btn {
        margin-top: 20px;
        text-align: center;

        button {
            background-color: #244d72;
            color: white;
            padding: 10px;
            width: 99px;
        }
    }

    .save_btn {
        button {
            background-color: #244d72;
            color: white;
            padding: 5px 0;
            width: 76px;
        }
    }
}

.accordian_css {
    h2.accordion-header {
        line-height: 19px;
    }
    .accordion-button {
        font-size: 14px;
        line-height: 1.42857143;
        padding: 12px;
    }
    .accordion-button:focus {
        background-color: white;
        border-color: #ddd;
        box-shadow: none;
    }
    .accordion-button:not(.collapsed) {
        background-color: white;
    }
}
