@media only screen and (min-width: 521px) and (max-width: 1024px) {
  .filter_section {
    width: 100% !important;
    padding: 5px 16px 0 !important;
    grid-template-columns: 1fr 1fr !important;

    .profile_section {
      margin: 10px 0;
      padding: 0;
      width: 100%;
      .dropdown-basic {
      }
      button {
        width: 100%;
      }
    }
  }
  .select-picker {
    width: 100% !important;
  }
}
