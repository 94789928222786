@media only screen and (max-width: 991px) {
    // filter //
    .filter_section {
        width: 100% !important;
    }

    .link_setting_container {
        grid-template-columns: 1fr;
    }

    .theme .theme_section {
        grid-template-columns: 1fr;
    }

    .camapgin_btn {
        display: block !important;
    }

    .filTypeBox {
        display: block !important;
    }

    .campaign_first {
        padding-right: 0;
    }

    .rs-picker-toggle-wrapper {
        width: 100% !important;
    }

    .setting-box {
        width: 70%;
    }

    .campaign_filter {
        grid-template-columns: 1fr;
    }

    .vertical_table {
        text-align: start;
    }

    .campaign_btn {
        grid-template-columns: 1fr;
    }

    .sb_container .campaign_btn {
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 521px) and (max-width: 991px) {
    //dashbord price //
    .price_section {
        grid-template-columns: 1fr 1fr !important;
    }

    .box.box_second {
        margin-right: 0 !important;
    }

    .box.box_first,
    .box.box_second {
        margin-bottom: 13px !important;
    }

    .price_section .box .inner_box .content span {
        font-size: 14px !important;
    }

    .price_section .box .inner_box i {
        font-size: 16px;
    }
}
